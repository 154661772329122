import { Checkbox, ChoiceGroup, createTheme, DefaultButton, Dropdown, IButtonStyles, initializeIcons, Label, Link, loadTheme, PrimaryButton, ProgressIndicator, Text, TextField } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ChartistGraph from 'react-chartist';
import { useRecaptcha } from 'react-recaptcha-hook';
import './App.css';


const btnStyles: IButtonStyles = {
  rootHovered: {
    backgroundColor: "rgb(255,95,80)",
    border: 0
  },
  rootPressed: {
    backgroundColor: "#1a1b4a",
    border: 0
  },
  root: {
    fontSize: '18px',
    padding: '20px',
    border: 0,
    borderRadius: '10px',
    width: '100%'
  }
};

function App() {

  initializeIcons();

  var sectors = useRef<string[]>([]);
  var formcode = useRef<string>('');

  useEffect(() => {
    const params = (new URL(window.location.href)).searchParams;
    const code = params.get('code');
    if (code && code === 'REVMQQ==') {
      sectors.current = ['Uitvaartverzorging', 'Uitvaartdiensten', 'Staf & Holding', 'IT', 'Coöperatie en Verzekeren', 'Klantcontactcentrum'];
      formcode.current = code;
    }
    else if (code) {
      formcode.current = code
    }
  }, []);

  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<any>({});
  const [IsCareGiver, setIsCareGiver] = useState<boolean>(false);

  loadTheme(createTheme({
    // defaultFontStyle: { fontFamily: 'GT-Walsheim-Regular', fontWeight: 'regular' },
    defaultFontStyle: { fontFamily: 'Verdana', fontWeight: 'regular', color: '#000000' },
    palette: {
      themePrimary: 'rgb(255,95,80)',//'#11b3c0',
      neutralPrimary: 'rgb(7, 18, 81)',
      neutralSecondary: 'rgb(7, 18, 81)'
    }
  }));

  // loadTheme(createTheme({
  //   defaultFontStyle: { fontFamily: 'GT-Walsheim-Regular', fontWeight: 'regular' },
  //   fonts: {
  //     // medium: { fontSize: 50 }
  //   },
  //   palette: {
  //     themePrimary: '#F126FF'
  //   }
  // }));

  return (
    <div className="main" >
      <div className="main-inner">
        <div style={{ width: '100%', textAlign: 'center', padding: '0 0 15px 0' }} >
          <img style={{ width: '150px' }} src="./logo.svg" alt="Fello" />
        </div>

        {step === -1 &&
          <div>

          </div>
        }


        {step === 0 &&
          <div style={{ padding: '25px 0', textAlign: 'center', width: '100%' }} >
            <div style={{ padding: '0 0 30px 0' }}>
              <div>
                <Text>
                  Als organisatie willen we graag inzicht krijgen in het aantal mantelzorgers en hen ondersteuning bieden wanneer dat nodig is. Door het invullen van de vragenlijst krijg jij ook zicht op je eigen situatie. We hopen dat Fello, wanneer je mantelzorger bent, jou kan helpen een goede balans te vinden om het zorgen op een prettige manier vol te houden. De hulp van Fello is gratis voor jou. Je kunt bij Fello terecht met grote vragen als ‘hoe regel ik een PGB, WMO of WLZ aanvraag’, maar ook met kleine vragen zoals: Komt mijn moeder in aanmerking voor huishoudelijke ondersteuning?
                </Text>
              </div>
              <br></br>
              <div>
                <Text variant="large" style={{ color: '#141441' }}>
                  Hoe zit het met mijn privacy?
                </Text>
              </div>
              <br></br>
              <div>
                <Text>
                  Natuurlijk gaan wij heel zorgvuldig om met jouw gegevens. We delen alleen de resultaten van de scan op organisatieniveau zodat je werkgever een beeld krijgt bij de impact van mantelzorg op alle medewerkers. De resultaten zijn nooit te herleiden naar één persoon of afdeling. Wanneer je een traject start met een Fello coach, weet hij/zij natuurlijk wat er bij jou speelt, en heeft hij/zij bepaalde gegevens van jou nodig. Maar niets daarvan wordt bij Fello opgeslagen. En het komt ook niet bij jouw werkgever terecht.
                </Text>
              </div>
              <br></br>
            </div>
            <PrimaryButton text="Open de vragenlijst" styles={btnStyles} onClick={() => setStep(1)} />
          </div>
        }

        {step === 1 &&
          <Form onsave={(answers, isCareGiver, token) => {
            fetch('https://prod-135.westeurope.logic.azure.com:443/workflows/5d624f507543433fae3b7d82b94272ce/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=qOXcXTfVzMXylTP3fhxmO7FKBkZL1VH817MhZWrtVtY',
              {
                method: 'post',
                headers: {
                  'content-type': 'application/json'
                },
                body: JSON.stringify({
                  token: token,
                  code: formcode.current,
                  answers: answers
                })
              })
              .catch(err => {
                console.log(err);
                alert('Er is een probleem bij het vezenden van het formulier. Probeer het later nog eens.');
              })
              .then((res: any) => {
                if (res.ok === false) {
                  alert('Er is een probleem bij het vezenden van het formulier. Probeer het later nog eens.');
                } else {
                  setAnswers(answers);
                  setIsCareGiver(isCareGiver);
                  setStep(2);
                }
              })


          }} sectors={sectors.current} />
        }

        {step === 2 && !IsCareGiver &&
          <div style={{ textAlign: 'center', padding: '0 0 30px 0' }}>
            <div style={{ padding: '30px 0 5px 0' }}>
              <Text variant={'xLarge'}>Je vindt jezelf geen mantelzorger</Text>
            </div>
            <br />
            <div>
              <Text variant='medium'>
                Mocht dat voor jou in de toekomst veranderen, of denk je dat je toch hulp nodig hebt? Neem dan contact op via 085 303 62 96 of coach@getfello.com, ook voor de hele kleine vragen.
              </Text>
            </div>
          </div>
        }

        {step === 2 && IsCareGiver &&
          <Results code={formcode.current} answers={answers} />
        }
      </div>
    </div>
  );
}

export default App;


export const Form: React.FC<FormProps> = (props: FormProps) => {

  const questions = [
    {
      number: 100,
      step: 0,
      name: '(Prive) e-mail adres'
    },
    {
      number: 101,
      step: 0,
      name: 'Geslacht'
    },
    {
      number: 102,
      step: 0,
      name: 'Leeftijd'
    },
    {
      number: 103,
      step: 0,
      name: 'Aantal uren werkzaam volgens contract'
    },
    {
      number: 5,
      step: 0,
      name: 'Functie of sector'
    },

    {
      number: 200,
      step: 1,
      name: 'Zorg jij voor iemand die extra hulp nodig heeft?'
    },
    // {
    //   number: 201,
    //   step: 1,
    //   name: 'Doe je dit omdat je een persoonlijke band hebt met die persoon?'
    // },
    // {
    //   number: 202,
    //   step: 1,
    //   name: 'Doe je dit minimaal 8 uur per week?'
    // },
    // {
    //   number: 203,
    //   step: 1,
    //   name: 'Is de zorg die je verricht onbetaald?'
    // },

    {
      number: 301,
      step: 2,
      name: 'Voor wie zorg je?'
    },
    {
      number: 302,
      step: 2,
      name: 'Wat doe je allemaal voor je naaste?'
    },
    {
      number: 303,
      step: 2,
      name: 'Hoeveel moeite kost het je om deze taken uit te voeren?'
    },
    {
      number: 304,
      step: 2,
      name: 'Hoeveel uur per week ben je ongeveer kwijt aan mantelzorgtaken inclusief bezoek en gezelschap?'
    },
    {
      number: 305,
      step: 2,
      name: 'Hoeveel uur per week besteed je echt aan jezelf? Bijvoorbeeld door een boek te lezen, te sporten, tv te kijken, met een vriend/vriendin op stap te gaan, breien of gewoon relaxen?'
    },
    {
      number: 401,
      step: 3,
      name: 'Hoe is jouw balans qua mantelzorgen, werken, gezin en vrije tijd?'
    },
    {
      number: 402,
      step: 3,
      name: 'Hoe lang houd je het vol als de situatie zo blijft?'
    },
    {
      number: 403,
      step: 3,
      name: 'Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?'
    },
    {
      number: 501,
      step: 4,
      name: 'Er zijn in Nederland veel wettelijke regelingen op het gebied van mantelzorgen. Ik weet van welke wettelijke regelingen ik gebruik kan maken ter ondersteuning en ben in staat deze zelf aan te vragen.'
    },
    {
      number: 502,
      step: 4,
      name: 'Ik heb moeite om mijn werk en het mantelzorgen te combineren'
    },
    {
      number: 502,
      step: 4,
      name: 'Mijn leidinggevende weet dat ik mantelzorger ben'
    }
  ]

  const [formStep, setFormStep] = useState(0);
  const [nextButtonEnables, setNextButtonEnabled] = useState<boolean>(true);
  const [isCareGiver, setIsCareGiver] = useState<boolean>(false);

  const { register, handleSubmit, clearErrors, getValues, control, formState: { errors } } = useForm();

  const execute = useRecaptcha({ sitekey: "6LeOOggcAAAAAMgoemU5E7Um5NRToQRdmxWdxk-B", hideDefaultBadge: true });

  const page = (page: number) => {
    setFormStep(page);
    clearErrors();
  }

  function _multiselectChanged(field: string, value: string, isChecked?: boolean) {
    var values = getValues(field);
    if (!values) {
      values = [];
    }
    if (isChecked)
      values.push(value);
    else
      values = values.filter((v: string) => v !== value);

    return values;
  }

  const getStepName = () => {
    switch (formStep) {
      case 0:
        return "Algemene gegevens"
      case 1:
        return "Ben jij mantelzorger?"
      case 2:
        return "Mantelzorgtaken"
      case 3:
        return "Welzijn"
      case 4:
        return "Werk"
      default:
        return ''
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form id="hook-form">
        <div style={{ margin: '0px 0px 20px 0' }}>
          <ProgressIndicator
            label={getStepName()}
            description={`Stap ${(formStep + 1)} van 5`}
            percentComplete={((formStep + 1) / 5)}
            styles={{
              itemName: {
                fontSize: '20px',
                paddingBottom: '5px'
              },
              itemDescription: {
                fontSize: '16px',
                paddingTop: '5px'
              }
            }}
          />
        </div>
        <div style={{ display: formStep === 0 ? 'initial' : 'none' }} >

          {/* <div style={{ padding: '30px 0 5px 0' }}>
            <Text variant={'xLarge'}>Algemene gegevens</Text>
          </div> */}


          {/* <Controller
            name="(Prive) e-mail adres"
            control={control}
            defaultValue=""
            rules={{
              required: false, pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            }}
            render={({ field }) =>
              <TextField
                label="(Prive) e-mail adres"
                type="email"
                errorMessage={errors['(Prive) e-mail adres'] ? 'Geen geldig e-mail adres' : undefined}
                required={false}
                onChange={field.onChange}
              />
            }
          /> */}

          <Controller
            name="Geslacht"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) =>
              <Dropdown
                label="Geslacht"
                errorMessage={errors['Geslacht'] ? 'Dit veld is verplicht' : undefined}
                required={true}
                onChange={(_e, option) => {
                  field.onChange(option?.key);
                }}
                placeholder="Selecteer een optie"
                options={[{ key: 'man', text: 'man' }, { key: 'vrouw', text: 'vrouw' }, { key: 'anders', text: 'anders' }]}
              />
            }
          />

          <Controller
            name="Leeftijd"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) =>
              <Dropdown
                label="Leeftijd"
                errorMessage={errors['Leeftijd'] ? 'Dit veld is verplicht' : undefined}
                required={true}
                onChange={(_e, option) => {
                  field.onChange(option?.key);
                }}
                placeholder="Selecteer een optie"
                options={[
                  { key: '20-29', text: '20-29' },
                  { key: '30-39', text: '30-39' },
                  { key: '40-49', text: '40-49' },
                  { key: '50-59', text: '50-59' },
                  { key: '60-70', text: '60-70' },
                ]}
              />
            }
          />

          <Controller
            name="Aantal uren werkzaam volgens contract"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) =>
              <Dropdown
                label="Aantal uren werkzaam volgens contract"
                errorMessage={errors['Aantal uren werkzaam volgens contract'] ? 'Dit veld is verplicht' : undefined}
                required={true}
                onChange={(_e, option) => {
                  field.onChange(option?.key);
                }}
                placeholder="Selecteer een optie"
                options={[
                  { key: '0-6 uur', text: '0-6 uur' },
                  { key: '7-12 uur', text: '7-12 uur' },
                  { key: '13-24 uur', text: '13-24 uur' },
                  { key: '25-32 uur', text: '25-32 uur' },
                  { key: '33-40 uur', text: '33-40 uur' },
                  { key: '40+ uur', text: '40+ uur' },
                ]}
              />
            }
          />

          {props.sectors && props.sectors.length > 0 &&
            <Controller
              name="Functie of sector"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) =>
                <Dropdown
                  label="Functie of sector"
                  errorMessage={errors['Functie of sector'] ? 'Dit veld is verplicht' : undefined}
                  required={true}
                  onChange={(_e, option) => {
                    field.onChange(option?.key);
                  }}
                  placeholder="Selecteer een optie"
                  options={props.sectors ? props.sectors.map(s => { return { key: s, text: s } }) : []}
                />
              }
            />

          }

        </div>

        <div style={{ display: formStep === 1 ? 'initial' : 'none' }} >

          {/* <div style={{ padding: '30px 0 5px 0' }}>
            <Text variant={'xLarge'}>Ben jij mantelzorger?</Text>
          </div> */}

          <div style={{ padding: '0 0 30px 0' }}>
            <Text>
              Volgens de definitie van het Sociaal Cultureel Planbureau ben je mantelzorger als je onbetaalde zorg biedt aan een hulpbehoevende uit je directe sociale omgeving.
            </Text>
            <br />
            {/* <div>
              <Text variant='medium'>
                Zorgen voor je naaste is voor de meeste mensen vanzelfsprekend. Iedereen krijgt vroeg of laat te maken met mantelzorg en mensen doen dit uit liefde voor elkaar. Maar wat verstaan we onder mantelzorg en wat niet.
              </Text>
            </div>
            <br />
            <div>
              <Text variant='medium'>
                Mantelzorg is alle hulp aan een hulpbehoevende door iemand uit diens directe sociale omgeving. Denk daarbij aan gezelschap, verzorging, vervoer en begeleiding naar arts, administratie, boodschappen, schoonmaken, klussen, koken of eenvoudige zorgtaken. Ook hulp aan huisgenoten en aan instellingsbewoners zijn meegenomen. Mantelzorg is hulp die verder gaat dan de zogenoemde ‘gebruikelijke hulp’. Mantelzorg is onbetaald en vrijwillig. (Definitie Sociaal Cultureel Planbureau).
              </Text>
            </div> */}
          </div>


          <Controller
            name="Zorg jij voor iemand die extra hulp nodig heeft?"
            control={control}
            defaultValue={undefined}
            rules={{ required: true }}
            render={({ field }) =>
              <>
                <Label>Zorg jij voor iemand die extra hulp nodig heeft?</Label>
                <Text variant='medium'>De zorg kan bestaan uit het doen van het huishouden, wassen en aankleden, gezelschap houden, vervoer, geldzaken regelen enzovoort.</Text>
                <Dropdown
                  // label="Zorg je al langer dan 3 maanden voor iemand die extra hulp nodig heeft?"
                  errorMessage={errors['Zorg jij voor iemand die extra hulp nodig heeft?'] ? 'Dit veld is verplicht' : undefined}
                  required={true}
                  selectedKey={undefined}
                  onChange={(_e, option) => {
                    setIsCareGiver(option?.key === 'ja');
                    field.onChange(option?.key);
                  }}
                  placeholder="Selecteer een optie"
                  options={[{ key: 'ja', text: 'ja' }, { key: 'nee', text: 'nee' }]}
                />
              </>
            }
          />

          {/* {isCareGiver &&
            <>
              <Controller
                name="Doe je dit omdat je een persoonlijke band hebt met die persoon?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Doe je dit omdat je een persoonlijke band hebt met die persoon?"
                    errorMessage={errors['Doe je dit omdat je een persoonlijke band hebt met die persoon?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[{ key: 'ja', text: 'ja' }, { key: 'nee', text: 'nee' }]}
                  />
                }
              />

              <Controller
                name="Doe je dit minimaal 8 uur per week?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Doe je dit minimaal 8 uur per week?"
                    errorMessage={errors['Doe je dit minimaal 8 uur per week?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[{ key: 'ja', text: 'ja' }, { key: 'nee', text: 'nee' }]}
                  />
                }
              />

              <Controller
                name="Is de zorg die je verricht onbetaald?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Is de zorg die je verricht onbetaald?"
                    errorMessage={errors['Is de zorg die je verricht onbetaald?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[{ key: 'ja', text: 'ja' }, { key: 'nee', text: 'nee' }]}
                  />
                }
              />
            </>
          } */}
        </div>

        {isCareGiver &&
          <>
            <div style={{ display: formStep === 2 ? 'initial' : 'none' }} >

              {/* <div style={{ padding: '30px 0 5px 0' }}>
                <Text variant={'xLarge'}>Mantelzorgtaken</Text>
              </div> */}


              {/* <Controller
                name="Voor wie zorg je?"
                control={control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field }) =>
                  <>
                    <Label required={true}>Voor wie zorg je?</Label>
                    <Text>(meerdere antwoorden mogelijk)</Text>
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="partner" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Partner', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="(schoon)ouder" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Een (schoon)ouder', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="(schoon)broer/zus" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Een(schoon)broer/zus', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="kind" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Een kind', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="ander familielid" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Een ander familielid', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="vriend of bekende" onChange={(ev, checked) => field.onChange(_multiselectChanged('Voor wie zorg je?', 'Een vriend of bekende', checked))} />
                    {errors['Voor wie zorg je?'] &&
                      <Text variant="small" style={{ color: '#a4262c' }}>Dit veld is verplicht</Text>
                    }
                  </>
                }
              /> */}


              <Controller
                name="Wat doe je allemaal voor je naaste?"
                control={control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field }) =>
                  <>
                    <Label required={true}>Wat doe je allemaal voor je naaste?</Label>
                    <Text>(meerdere antwoorden mogelijk)</Text>
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="regeltaken" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'regeltaken', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="maatje" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'maatje', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="boekhouding" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'boekhouding', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="chauffeur" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'chauffeur', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="verzorgen" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'verzorgen', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="koken" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'koken', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="klusjes" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'klusjes', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="huishouden" onChange={(ev, checked) => field.onChange(_multiselectChanged('Wat doe je allemaal voor je naaste?', 'huishouden', checked))} />
                    {errors['Wat doe je allemaal voor je naaste?'] &&
                      <Text variant="small" style={{ color: '#a4262c' }}>Dit veld is verplicht</Text>
                    }
                  </>
                }
              />


              <Controller
                name="Hoeveel moeite kost het je om deze taken uit te voeren?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Hoeveel moeite kost het je om deze taken uit te voeren? (1 is weinig 5 is veel)"
                    errorMessage={errors['Hoeveel moeite kost het je om deze taken uit te voeren?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: '1', text: '1' },
                      { key: '2', text: '2' },
                      { key: '3', text: '3' },
                      { key: '4', text: '4' },
                      { key: '5', text: '5' },
                    ]}
                  />
                }
              />


              <Controller
                name="Hoeveel uur per week ben je ongeveer kwijt aan mantelzorgtaken inclusief bezoek en gezelschap?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Hoeveel uur per week ben je ongeveer kwijt aan mantelzorgtaken inclusief bezoek en gezelschap?"
                    errorMessage={errors['Hoeveel uur per week ben je ongeveer kwijt aan mantelzorgtaken inclusief bezoek en gezelschap?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: '0-6 uur', text: '0-6 uur' },
                      { key: '7-12 uur', text: '7-12 uur' },
                      { key: '13-24 uur', text: '13-24 uur' },
                      { key: '25-32 uur', text: '25-32 uur' },
                      { key: '33-40 uur', text: '33-40 uur' },
                      { key: '40+ uur', text: '40+ uur' },
                    ]}
                  />
                }
              />


              <Controller
                name="Hoeveel uur per week besteed je echt aan jezelf? Bijvoorbeeld door een boek te lezen, te sporten, tv te kijken, met een vriend/vriendin op stap te gaan, breien of gewoon relaxen?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Hoeveel uur per week besteed je echt aan jezelf? Bijvoorbeeld door een boek te lezen, te sporten, tv te kijken, met een vriend/vriendin op stap te gaan, breien of gewoon relaxen?"
                    errorMessage={errors['Hoeveel uur per week besteed je echt aan jezelf? Bijvoorbeeld door een boek te lezen, te sporten, tv te kijken, met een vriend/vriendin op stap te gaan, breien of gewoon relaxen?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: '0-6 uur', text: '0-6 uur' },
                      { key: '7-12 uur', text: '7-12 uur' },
                      { key: '13-24 uur', text: '13-24 uur' },
                      { key: '25-32 uur', text: '25-32 uur' },
                      { key: '33-40 uur', text: '33-40 uur' },
                      { key: '40+ uur', text: '40+ uur' },
                    ]}
                  />
                }
              />


            </div>

            <div style={{ display: formStep === 3 ? 'initial' : 'none' }} >


              {/* <div style={{ padding: '30px 0 5px 0' }}>
                <Text variant={'xLarge'}>Welzijn</Text>
              </div> */}

              <Controller
                name="Hoe is jouw balans qua mantelzorgen, werken, gezin en vrije tijd?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Hoe is jouw balans qua mantelzorgen enerzijds en werken, gezin en vrije tijd anderzijds? (1 er moet iets veranderen, 5 is goed in balans)"
                    errorMessage={errors['Hoe is jouw balans qua mantelzorgen, werken, gezin en vrije tijd?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: '1', text: '1' },
                      { key: '2', text: '2' },
                      { key: '3', text: '3' },
                      { key: '4', text: '4' },
                      { key: '5', text: '5' },
                    ]}
                  />
                }
              />


              <Controller
                name="Hoe lang houd je het vol als de situatie zo blijft?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Hoe lang houd je het vol als de situatie zo blijft?"
                    errorMessage={errors['Hoe lang houd je het vol als de situatie zo blijft?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: 'korter dan 1 maand', text: 'korter dan 1 maand' },
                      { key: '3 maanden', text: '3 maanden' },
                      { key: '6 maanden', text: '6 maanden' },
                      { key: '1 jaar', text: '1 jaar' },
                      { key: 'langer dan 2 jaar', text: 'langer dan 2 jaar' },
                      { key: 'Ik ervaar nu geen probleem', text: 'Ik ervaar nu geen probleem' },
                      { key: 'Weet ik niet', text: 'Weet ik niet' },
                    ]}
                  />
                }
              />

              <Controller
                name="Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?"
                control={control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({ field }) =>
                  <>
                    <Label required={true}>Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?</Label>
                    <Text>(meerdere antwoorden mogelijk)</Text>
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="nee, het is goed zo" onChange={(ev, checked) => field.onChange(_multiselectChanged('Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?', 'nee, het is goed zo', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="Ik zou meer tijd voor mijn gezin willen hebben" onChange={(ev, checked) => field.onChange(_multiselectChanged('Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?', 'Ik zou meer tijd voor mijn gezin willen hebben', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="Ik zou meer tijd voor vrije tijd willen hebben" onChange={(ev, checked) => field.onChange(_multiselectChanged('Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?', 'Ik zou meer tijd voor vrije tijd willen hebben', checked))} />
                    <Checkbox styles={{ root: { padding: '5px 0' } }} label="Ik zou graag andere mantelzorgtaken willen doen" onChange={(ev, checked) => field.onChange(_multiselectChanged('Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?', 'Ik zou graag andere mantelzorgtaken willen doen', checked))} />
                    {errors['Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?'] &&
                      <Text variant="small" style={{ color: '#a4262c' }}>Dit veld is verplicht</Text>
                    }
                  </>
                }
              />

              {/* <Controller
                name="Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?"
                    errorMessage={errors['Zou je jouw balans tussen mantelzorgen, werken, gezin en vrije tijd willen verbeteren?'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: 'nee, het is goed zo', text: 'nee, het is goed zo' },
                      { key: 'ja, ik zou meer tijd voor mijn gezin willen hebben', text: 'ja, ik zou meer tijd voor mijn gezin willen hebben' },
                      { key: 'ja, ik zou meer tijd voor vrije tijd willen hebben', text: 'ja, ik zou meer tijd voor vrije tijd willen hebben' },
                      { key: 'ja, ik zou graag andere mantelzorgtaken willen doen', text: 'ja, ik zou graag andere mantelzorgtaken willen doen' }
                    ]}
                  />
                }
              /> */}

            </div>

            <div style={{ display: formStep === 4 ? 'initial' : 'none' }} >

              {/* <div style={{ padding: '30px 0 5px 0' }}>
                <Text variant={'xLarge'}>Werk</Text>
              </div> */}


              <Controller
                name="Ik weet van welke wettelijke regelingen ik gebruik kan maken ter ondersteuning en ben in staat deze zelf aan te vragen."
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Er zijn in Nederland veel wettelijke regelingen op het gebied van mantelzorgen. Ik weet van welke wettelijke regelingen ik gebruik kan maken ter ondersteuning en ben in staat deze zelf aan te vragen."
                    errorMessage={errors['Ik weet van welke wettelijke regelingen ik gebruik kan maken ter ondersteuning en ben in staat deze zelf aan te vragen.'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: 'helemaal mee eens', text: 'helemaal mee eens' },
                      { key: 'grotendeels mee eens', text: 'grotendeels mee eens' },
                      { key: 'geen mening', text: 'geen mening' },
                      { key: 'grotendeels oneens', text: 'grotendeels oneens' },
                      { key: 'helemaal mee oneens', text: 'helemaal mee oneens' }
                    ]}
                  />
                }
              />


              <Controller
                name="Ik heb moeite om mijn werk en het mantelzorgen te combineren"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Ik heb moeite om mijn werk en het mantelzorgen te combineren"
                    errorMessage={errors['Ik heb moeite om mijn werk en het mantelzorgen te combineren'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: 'helemaal mee eens', text: 'helemaal mee eens' },
                      { key: 'grotendeels mee eens', text: 'grotendeels mee eens' },
                      { key: 'geen mening', text: 'geen mening' },
                      { key: 'grotendeels oneens', text: 'grotendeels oneens' },
                      { key: 'helemaal mee oneens', text: 'helemaal mee oneens' }
                    ]}
                  />
                }
              />


              <Controller
                name="Mijn werkgever/leidinggevende weet dat ik mantelzorger ben"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) =>
                  <Dropdown
                    label="Mijn werkgever/leidinggevende weet dat ik mantelzorger ben"
                    errorMessage={errors['Mijn werkgever/leidinggevende weet dat ik mantelzorger ben'] ? 'Dit veld is verplicht' : undefined}
                    required={true}
                    onChange={(_e, option) => {
                      field.onChange(option?.key);
                    }}
                    placeholder="Selecteer een optie"
                    options={[
                      { key: 'ja', text: 'ja' },
                      { key: 'geen antwoord', text: 'geen antwoord' },
                      { key: 'nee', text: 'nee' },
                    ]}
                  />
                }
              />


            </div>
          </>
        }
        <div style={{ marginTop: '20px' }}>
          <Text>
            Velden met een * zijn verplicht
          </Text>
        </div>
      </form>


      <div style={{ padding: '25px 0', textAlign: 'center' }}>
        {formStep !== 0 &&

          <DefaultButton
            text="Terug"
            onClick={() => {
              page(formStep - 1)
            }}
            styles={{
              rootHovered: {
                // backgroundColor: "rgb(255,95,80)",
                // border: 0
              },
              rootPressed: {
                backgroundColor: "#141441",
                border: 0
              },
              root: {
                width: '50%',
                borderRadius: '10px'
              }
            }}
          />
        }
        <PrimaryButton
          text="Verder"
          disabled={!nextButtonEnables}
          onClick={(e) => {
            handleSubmit(
              async (d) => {
                // form is complete
                setNextButtonEnabled(false);
                const token = await execute('Form1');
                props.onsave(d, isCareGiver, token);
              },
              (errors => {
                // // check is caregiver
                // if (formStep === 1) {
                //   const x = getValues();
                //   console.log(x);
                //   return;
                // }
                // check for errors on current page            
                var goToNextStep: boolean = true;
                if (errors) {
                  questions.filter(f => f.step === formStep).map(q => {
                    if (Object.keys(errors).findIndex(e => e === q.name) !== -1) {
                      goToNextStep = false;
                    }
                  });
                }
                if (goToNextStep)
                  page(formStep + 1)
              })
            )();
          }}
          styles={{
            rootHovered: {
              backgroundColor: "rgb(255,95,80)",
              border: 0
            },
            rootPressed: {
              backgroundColor: "#141441",
              border: 0
            },
            root: {
              width: formStep === 0 ? '100%' : '50%',
              borderRadius: '10px'
            }
          }}
        />
        {
          Object.keys(errors).length > 0 &&
          <div style={{ margin: '10px 0 0 0' }}>
            <Text styles={{ root: { color: 'red' } }} variant={'medium'}>Niet alle velden zijn juist ingevuld</Text>
          </div>
        }
      </div>
    </div>
  )
}

interface FormProps {
  onsave: (answers: any, isCareGiver: boolean, token: string) => void;
  sectors?: string[];
}

export const Results: React.FC<ResultProps> = (props: ResultProps) => {

  const [score, setScore] = useState<number>(0);
  const [series, setSeries] = useState([1, 1, 1])

  const [contactFormChoice, setContactFormChoice] = useState<string>("0")
  const [contactFormSent, setContactFormSent] = useState<number>(0);

  const { register, handleSubmit, clearErrors, getValues, control, formState: { errors } } = useForm();

  const execute = useRecaptcha({ sitekey: "6LeOOggcAAAAAMgoemU5E7Um5NRToQRdmxWdxk-B", hideDefaultBadge: true });

  useEffect(() => {
    // console.log(props);
    var score: number = 0;
    var serie1: number = 1;
    var serie2: number = 1;
    var serie3: number = 1;
    const q3 = props.answers['Aantal uren werkzaam volgens contract'];
    switch (q3) {
      case "0-6 uur":
        serie2 = 3;
        break;
      case "7-12 uur":
        serie2 = 10;
        break;
      case "13-24 uur":
        serie2 = 18;
        break;
      case "25-32 uur":
        serie2 = 28;
        break;
      case "33-40 uur":
        serie2 = 37;
        break;
      case "40+ uur":
        serie2 = 43;
        break;
      default:
        break;
    }
    //
    const q8 = props.answers['Hoeveel moeite kost het je om deze taken uit te voeren?'];
    score = score + parseInt(q8);
    //
    const q9 = props.answers['Hoeveel uur per week ben je ongeveer kwijt aan mantelzorgtaken inclusief bezoek en gezelschap?'];
    switch (q9) {
      case "0-6 uur":
        score = score + 1;
        serie1 = 3;
        break;
      case "7-12 uur":
        score = score + 2;
        serie1 = 10;
        break;
      case "13-24 uur":
        score = score + 3;
        serie1 = 18;
        break;
      case "25-32 uur":
        score = score + 4;
        serie1 = 28;
        break;
      case "33-40 uur":
        score = score + 5;
        serie1 = 37;
        break;
      case "40+ uur":
        score = score + 6;
        serie1 = 43;
        break;
      default:
        break;
    }
    //
    const q10 = props.answers['Hoeveel uur per week besteed je echt aan jezelf? Bijvoorbeeld door een boek te lezen, te sporten, tv te kijken, met een vriend/vriendin op stap te gaan, breien of gewoon relaxen?'];
    switch (q10) {
      case "0-6 uur":
        score = score + 1;
        serie3 = 3;
        break;
      case "7-12 uur":
        score = score + 2;
        serie3 = 10;
        break;
      case "13-24 uur":
        score = score + 3;
        serie3 = 18;
        break;
      case "25-32 uur":
        score = score + 4;
        serie3 = 28;
        break;
      case "33-40 uur":
        score = score + 5;
        serie3 = 37;
        break;
      case "40+ uur":
        score = score + 6;
        serie3 = 43;
        break;
      default:
        break;
    }
    //
    const q11 = props.answers['Hoe is jouw balans qua mantelzorgen, werken, gezin en vrije tijd?'];
    switch (q11) {
      case "1":
        score = score + 5;
        break;
      case "2":
        score = score + 4;
        break;
      case "3":
        score = score + 3;
        break;
      case "4":
        score = score + 2;
        break;
      case "5":
        score = score + 1;
        break;
      default:
        break;
    }
    //
    const q12 = props.answers['Hoe lang houd je het vol als de situatie zo blijft?'];
    switch (q12) {
      case "korter dan 1 maand":
        score = score + 10;
        break;
      case "3 maanden":
        score = score + 8;
        break;
      case "6 maanden":
        score = score + 6;
        break;
      case "1 jaar":
        score = score + 4;
        break;
      case "langer dan 2 jaar":
        score = score + 2;
        break;
      default:
        break;
    }
    //
    const q15 = props.answers['Ik heb moeite om mijn werk en het mantelzorgen te combineren'];
    switch (q15) {
      case "helemaal mee eens":
        score = score + 5;
        break;
      case "grotendeels mee eens":
        score = score + 4;
        break;
      case "geen mening":
        score = score + 3;
        break;
      case "grotendeel oneens":
        score = score + 2;
        break;
      case "helemaal mee oneens":
        score = score + 1;
        break;
      default:
        break;
    }
    // console.log(score);
    setScore(score);
    setSeries([serie1, serie2, serie3]);
  }, []);


  var listener = {

    draw: function (data: any) {
      // console.log(data);
      if (data.type === 'bar') {
        data.element.attr({
          style: 'stroke-width: 80px'
        });
      }
      if (data.type === 'grid') {
        data.element.attr({
          style: 'display: none'
        });
      }
      if (data.type === 'label') {
        data.element.attr({
          style: "height: 80px"
        });
      }
    }
  };

  return (
    <div>

      <ChartistGraph
        listener={listener}
        data={{
          labels: ['Zoveel uur besteed je aan mantelzorg', 'Zoveel uur werk je ', 'Zoveel uur heb je voor jezelf'],
          series: [
            series
          ]
        }}
        options={{
          high: 50,
          low: 0,
          // width: 500,
          height: 250,
          axisY: {
            labelInterpolationFnc: (value: any) => {
              return value;
            }
          },
          axisX: {
            offset: 80,
            labelInterpolationFnc: (value: any) => {
              // console.log(value);
              return value;
              // return "<div>{value}</div>";
            }
          }
        }}
        type={'Bar'}
      />

      <div style={{ padding: '10px 0 30px 0', textAlign: 'center', width: '100%' }}>

        {score < 15 &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0 30px 0' }}>
              <div>
                <img style={{
                  width: '95%',
                  marginBottom: '10px'
                }} src="/groen.png" alt="" />
                <Text variant="mediumPlus">
                  Je hebt alles onder controle
                </Text>
              </div>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/oranje.png" alt="" />
              </div>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/rood.png" alt="" />
              </div>
            </div>
            <Text variant="mediumPlus">
              Je lijkt in staat te zijn je mantelzorgtaken, je werk en gezin en vrije tijd goed te combineren. Blijf wel alert als de situatie verandert. Heeft degene waarvoor je zorgt ineens meer hulp nodig? Gaat je eigen gezondheid achteruit? Valt de zorg je zwaarder? Of heb je gewoon een korte vraag rondom de zorg of het mantelzorgen? Dan kun je contact opnemen met Fello
            </Text>
          </div>
        }

        {score > 14 && score < 25 &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0 30px 0' }}>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/groen.png" alt="" />
              </div>
              <div>
                <img style={{
                  width: '95%',
                  marginBottom: '10px'
                }} src="/oranje.png" alt="" />
                <Text variant="mediumPlus">
                  Je kunt je last nog dragen
                </Text>
              </div>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/rood.png" alt="" />
              </div>
            </div>
            <Text variant="mediumPlus">
              Je lijkt wat moeite te hebben je mantelzorgtaken, je werk, gezin en vrije tijd goed te combineren.  Wat zou je daar graag aan willen veranderen? Kun je dat zelf of heb je hulp nodig? Schroom niet om hulp te vragen bij Fello. Ook wanneer je alleen een korte vraag hebt rondom de zorg of het mantelzorgen kun je contact opnemen met Fello.
            </Text>
          </div>
        }

        {score > 24 &&
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0 30px 0' }}>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/groen.png" alt="" />
              </div>
              <div>
                <img style={{
                  width: '70%',
                  filter: 'opacity(0.5)'
                }} src="/oranje.png" alt="" />
              </div>
              <div>
                <img style={{
                  width: '95%',
                  marginBottom: '10px'
                }} src="/rood.png" alt="" />
                <Text variant="mediumPlus">
                  Je draagt een zware last
                </Text>
              </div>
            </div>
            <Text variant="mediumPlus">
              Op dit moment lijk je het erg zwaar te hebben. Is de zorg zo veeleisend dat je nauwelijks aan andere dingen toekomt? Wacht niet langer, neem contact op met Fello. We staan direct voor je klaar en kijken samen naar jouw situatie en hoe we die kunnen verbeteren. Ook wanneer je alleen een korte vraag hebt rondom de zorg of het mantelzorgen kun je contact opnemen met Fello.
            </Text>
          </div>
        }

      </div>

      <div style={{ textAlign: 'center', padding: '0 0 30px 0' }}>
        <div>
          <PrimaryButton
            text="Print deze uitslag"
            styles={{
              root: {
                // width: formStep === 0 ? '100%' : '50%',
                borderRadius: '10px'

              }
            }}
            iconProps={{ iconName: 'Print' }}
            onClick={() => window.print()}
          />
        </div>
      </div>



      <div style={{ margin: '0px auto 10px', width: '500px' }}>
        <div>
          <ChoiceGroup label="Wil je op de hoogte gehouden worden of heb je een vraag m.b.t. het zorgen voor een naaste?"
            selectedKey={contactFormChoice}
            onChange={(val, option) => { if (option) setContactFormChoice(option.key) }}
            options={[{ text: 'Ik wil op de hoogte gehouden worden', key: "0" }, { text: 'Ik heb een hulpvraag', key: "1" }]}
          />
        </div>
      </div>


      {/* <div style={{ textAlign: 'center', padding: '0 0 30px 0' }}>
        <div>
          <Text styles={{ root: { fontWeight: 600 } }}>
            Wil je een vraag stellen aan Fello of wil je hulp bij de zorg voor een naaste?
          </Text>
        </div>
        <br />
        <div>
          <Text variant='medium'>
            Laat dan hieronder je gegevens achter. Fello neemt binnen 1 werkdag contact met je op.
          </Text>
        </div>
      </div> */}


      <div style={{ margin: '0 auto', width: '500px' }}>
        <Controller
          name="Naam"
          control={control}
          defaultValue={[]}
          rules={{ required: true }}
          render={({ field }) =>
            <TextField
              errorMessage={errors['Naam'] ? 'Dit veld is verplicht' : undefined}
              disabled={contactFormSent !== 0}
              onChange={field.onChange}
              required={true}
              label="Naam"
            />
          }
        />
        <Controller
          name="E-Mail"
          control={control}
          defaultValue={[]}
          rules={{ required: true }}
          render={({ field }) =>
            <TextField
              errorMessage={errors['E-Mail'] ? 'Dit veld is verplicht' : undefined}
              disabled={contactFormSent !== 0}
              onChange={field.onChange}
              required={true}
              label="E-mail"
            />
          }
        />
        {contactFormChoice === "0" &&
          <Controller
            name="Akkoord"
            control={control}
            defaultValue={[]}
            rules={{ required: true }}
            render={({ field }) =>
              <Checkbox
                styles={{ root: { margin: '10px 0' } }}
                disabled={contactFormSent !== 0}
                onChange={field.onChange}
                required={true}
                onRenderLabel={() => {
                  return <div>
                    Ja, Fello mag mij maximaal 4 keer per jaar via e-mail relevante informatie over Fello of mantelzorgen toesturen. Ik heb kennis genomen van het <a target="_blank" href="https://www.fello.nl/privacy-statement">privacystatement</a>, waarin ik kan lezen hoe Fello met gegevens omgaat.
                  </div>
                }}

              />
            }
          />
        }
        {contactFormChoice === "1" &&
          <>
            <Controller
              name="Telefoonnummer"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  disabled={contactFormSent !== 0}
                  onChange={field.onChange}
                  required={true}
                  label="Telefoonnummer"
                  errorMessage={errors['Telefoonnummer'] ? 'Dit veld is verplicht' : undefined}
                />
              }
            />
            <Controller
              name="Akkoord"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field }) =>
                <Checkbox
                  styles={{ root: { margin: '10px 0' } }}
                  disabled={contactFormSent !== 0}
                  onChange={field.onChange}
                  required={true}
                  onRenderLabel={() => {
                    return <div>
                      Ja, Fello mag contact met me opnemen over mijn hulpvraag. Ik heb kennis genomen van het <a target="_blank" href="https://info.getfello.com/privacystatement/">privacystatement</a>, waarin ik kan lezen hoe Fello met gegevens omgaat.
                    </div>
                  }}
                />
              }
            />
          </>
        }

        {errors && errors['Akkoord'] &&
          <div>
            <Text style={{ color: 'rgb(164, 38, 44)' }}>Je moet akkoord gaan om het formulier te verzenden</Text>
          </div>
        }



        <PrimaryButton style={{ margin: '20px 0', width: '100%' }} text="Verzend aanvraag" disabled={contactFormSent !== 0}
          styles={{
            root: {
              // width: formStep === 0 ? '100%' : '50%',
              borderRadius: '10px'

            }
          }}
          onClick={(e) => {
            handleSubmit(
              async (d) => {
                setContactFormSent(-1);
                const token = await execute('Form2');

                fetch('https://prod-192.westeurope.logic.azure.com:443/workflows/f9f1475a51aa437d8968fa336534a06d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=y2QcK5qXaBsySlBVclrbjWvCAkjR6UFV4KInHAHWp6g',
                  {
                    method: 'post',
                    headers: {
                      'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                      token: token,
                      code: props.code,
                      mode: contactFormChoice === "0" ? "Informatie" : "Contact",
                      answers: d
                    })
                  }).then((res) => {
                    if (res.status === 200) {
                      setContactFormSent(1);
                    } else {
                      setContactFormSent(2);
                    }
                  })
              },
              (errors => {
                // check for errors on current page            

              })
            )();


          }} />
      </div>


      {
        contactFormSent === 1 &&
        <div style={{ margin: '0 auto', width: '60%' }}>
          <Text variant='medium' style={{ fontWeight: 1000 }}>
            We hebben uw gegevens succesvol ontvangen
          </Text>
        </div>
      }

      {
        contactFormSent === 2 &&
        <div style={{ margin: '0 auto', width: '60%', color: 'red' }}>
          <Text variant='medium' style={{ fontWeight: 1000 }}>
            Er is iets misgegaan :(
          </Text>
        </div>

      }
      <div style={{ textAlign: 'center', padding: '0 0 30px 0' }}>
        <div>
          <Text variant='medium'>
            Ook kun je Fello bereiken op 085 303 62 96 of info@fello.nl wanneer je een gerichte vraag wilt stellen.
          </Text>
        </div>
        {props.code &&
          <div>
            <Text>
              <Link href={`https://info.getfello.com/${atob(props.code)}`} target="_new" underline>
                Of kijk op info.getfello.com/{atob(props.code)}
              </Link>
            </Text>
          </div>
        }
      </div>
    </div >
  )
}

interface ResultProps {
  answers: any;
  code: string;
}